import React, {useEffect, useState} from 'react';
import configs from '../../utils/config.json';
import './bridgeBox.scss';
import "react-tooltip/dist/react-tooltip.css";
import {Tooltip as ReactTooltip} from "react-tooltip";
import {HiOutlineExternalLink} from "react-icons/hi";
import {MdContentCopy} from "react-icons/md";
import loader from "../../assets/sharpLoader-unscreen.gif";

const BridgeBox = ({
                     account,
                     handleKavaChainChange,
                     isSwitchOn,
                     accountBalance,
                     walletBalance,
                     setIsSwitchOn,
                     handleGetValue,
                     handleBridge,
                     inputValue,
                     txHash,
                     approvedBalance,
                     handleMax,
                     chainId,
                     loading,
                     status,
                     handleApprove,
                     handleFantomChainChange
                   }) => {
  const [isTextCopied, setIsTextCopied] = useState(false);

  useEffect(() => {
    if (isSwitchOn) {
      handleFantomChainChange()
    } else {
      handleKavaChainChange()
    }
    // eslint-disable-next-line
  }, [isSwitchOn, chainId]);

  const handleURLCopied = () => {
    navigator.clipboard.writeText(txHash)
    setIsTextCopied(true)
  }

  const handleLeave = () => {
    setTimeout(() => {
      setIsTextCopied(false)
    }, 1000)
  }

  const switchChange = () => {
    setIsSwitchOn(!isSwitchOn)
    localStorage.setItem('switch', JSON.stringify(!isSwitchOn))
  }

  return (
    <div className='main_div'>
      <img
        className={`loading_img ${loading || status === 'initializing' || status === 'connecting' ? 'display' : ''}`}
        src={loader} alt='loading..'/>
      <div className={`${loading || status === 'initializing' || status === 'connecting' ? 'hide' : ''}`}>
        <div className='top_head_div'>
          <div className='heading_details'>
            <p className='heading'>Bridge $SHRAP to {isSwitchOn ? 'Kava' : 'Fantom'}</p>
            <p
              className='contract_details'>Contract: <a target='_blank'
                                                        rel="noreferrer"
                                                        href={`${isSwitchOn ? 'https://ftmscan.com/address/0xdEE35F89caEdA89Ba8BEd7ECa5e062581dEfB182' : 'https://explorer.kava.io/address/0xdEE35F89caEdA89Ba8BEd7ECa5e062581dEfB182'}`}>{isSwitchOn ? configs.fantomTokenAddress.slice(0, 5) + "..." + configs.fantomTokenAddress.slice(-4) : configs.kavaTokenAddress.slice(0, 5) + "..." + configs.kavaTokenAddress.slice(-4)}
              <HiOutlineExternalLink/></a>
            </p>
          </div>
          <div className='metamask_details'>
            <div className='switch_div'>
              <span className={`switch_text ${!isSwitchOn && 'dark_text'}`}>Kava</span>
              <label className="switch">
                <input type="checkbox" id='check_id' checked={isSwitchOn} onChange={switchChange}/>
                <span className="slider round"></span>
              </label>
              <span className={`switch_text ${isSwitchOn && 'dark_text'}`}>Fantom</span>
            </div>
            <p className='wallet_details'><span
              className='balance'>{Number(walletBalance).toFixed(4)} {isSwitchOn ? 'FTM' : 'KAVA'} </span> {account?.slice(0, 5) + "..." + account?.slice(-4)}
            </p>
          </div>
        </div>
        <div className='account_balance'>
          <p className='title'>Account Balance:</p>
          <p className='value'>{accountBalance} $SHRAP</p>
        </div>
        {
          isSwitchOn &&
          <div className='account_balance approve_div'>
            <p className='title'>Approved Balance:</p>
            <p className='value'>{approvedBalance} $SHRAP</p>
          </div>
        }
        <div className={`account_balance input_div ${!isSwitchOn && 'kava_box'}`}>
          <p className='title'>Amount
            to {isSwitchOn ? Number(approvedBalance) == 0 || Number(approvedBalance) < Number(inputValue) ? 'Approve' : 'Bridge' : 'Bridge'}:</p>
          <div className='input_details'>
            <input type='number'
                   onChange={(e) => handleGetValue(e)}
                   placeholder={inputValue}
                   id='bridge_amount'
                   autoComplete="off"/>
            <span className='max' onClick={handleMax}>MAX</span>
          </div>
        </div>
        {
          txHash.length > 0 ?
            <div className='txn_done'>
              <p className='success_message'>Bridging initiated!</p>
              <p className='hash'>Hash: {txHash} <MdContentCopy onMouseLeave={handleLeave} onClick={handleURLCopied}
                                                                id='copy_icon'/></p>
              <ReactTooltip
                anchorId="copy_icon"
                place="top"
                content={isTextCopied ? "hash copied!" : "copy hash to clipboard"}
              />
              <p className='go_to_link'>You can track the status <a target='_blank' rel="noreferrer"
                                                                    href={`https://axelarscan.io/gmp/${txHash}`}> here</a>.
              </p>
            </div> :
            <div className='bride_btn_div'>
              {isSwitchOn ?
                Number(approvedBalance) == 0 || Number(approvedBalance) < Number(inputValue) ?
                  <button className='bridge_btn' disabled={Number(inputValue) <= 0}
                          onClick={handleApprove}>Approve</button> :
                  <button className='bridge_btn'
                          disabled={Number(inputValue) <= 0 || Number(inputValue) > Number(accountBalance)}
                          onClick={handleBridge}>Bridge
                  </button> : <button className='bridge_btn'
                                      disabled={Number(inputValue) <= 0 || Number(inputValue) > Number(accountBalance)}
                                      onClick={handleBridge}>Bridge
                </button>
              }
            </div>
        }
      </div>
    </div>
  );
}

export default BridgeBox;
