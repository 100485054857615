import React from "react";
import './connect.scss';
import logo from '../../assets/shrapLogo1.png';


const Connect = ({ status, connect }) => {

  const connectWallet = () => {
    connect();
  };
  return (
    <>
      <div className='main__div'>
        <img src={logo} alt='logo'/>
        {status === "connected"
          ? <></>
          : <button
            onClick={
              status === "notConnected"
                ? connectWallet
                : null
            }
          >
            {status === "unavailable" ?
              <>Install MetaMask</>
              : null}

            {status === "notConnected" ?
              <>Connect</>
              : null}
          </button>
        }
      </div>
    </>
  )
}
export default Connect;