import React, {useEffect, useState} from 'react';
import './App.css';
import {useMetaMask} from "metamask-react";
import Connect from "./components/connect/Connect";
import BridgeBox from "./components/bridgeBox/BridgeBox";
import {ToastContainer, toast, Slide} from "react-toastify";
import configs from './utils/config.json'
import 'react-toastify/dist/ReactToastify.css';
import {
  approvedAmount,
  fantomApproval,
  fantomBalance,
  getWalletBalance,
  kavaBalance, lockTokensToFantom,
  lockTokensToKava,
  providerHandler
} from "./web3/contractInteraction";

const App = () => {
  const { status, connect, account, chainId } = useMetaMask();
  const [loading, setLoading] = useState(false);
  const [isSwitchOn, setIsSwitchOn] = useState(JSON.parse(localStorage.getItem('switch')) ? JSON.parse(localStorage.getItem('switch')) : false);
  const [accountBalance, setAccountBalance] = useState(0);
  const [inputValue, setInputValue] = useState(0);
  const [walletBalance, setWalletBalance] = useState(0);
  const [approvedBalance, setApprovedAmount] = useState(0);
  const [txHash, setTxHash] = useState('');

  useEffect(() => {
    providerHandler()
    if (status === 'connected') {
      getBalance()
      setInputValue(0)
      document.getElementById('bridge_amount').value = null;
      setTxHash('')
    }
    // eslint-disable-next-line
  }, [account, status, chainId])

  const getBalance = async () => {
    setLoading(true)
    const b = await getWalletBalance()
    setWalletBalance(b)
    let balance;
    if (chainId === configs.FantomMainnetChainId) {
      balance = await fantomBalance(account)
      const approveBalance = await approvedAmount(account)
      setApprovedAmount(approveBalance)
    }
    if (chainId === configs.KavaMainnetChainId) {
      balance = await kavaBalance(account)
    }
    setAccountBalance(balance)
    if (chainId === configs.FantomMainnetChainId && isSwitchOn || chainId === configs.KavaMainnetChainId && !isSwitchOn) {
      setLoading(false)
    }
  }

  const handleGetValue = (e) => {
    setInputValue(Number(e.target.value))
  }

  const handleMax = () => {
    setInputValue(accountBalance)
    document.getElementById('bridge_amount').value = accountBalance;
  }

  const handleApprove = async () => {
    if (Number(walletBalance) > 0) {
      setLoading(true)
      const approval = await fantomApproval(inputValue)
      if (approval) {
        toast('Transaction successful!', toastOptions)
      } else if (approval === false) {
        toast('User denied transaction!', toastOptions)
      }
      getBalance()
      setLoading(false)
    } else {
      toast('Insufficient FTM balance', { ...toastOptions, autoClose: 3000 })
    }
  }

  const handleBridge = async () => {
    setLoading(true)
    if (isSwitchOn) {
      const res = await lockTokensToKava(inputValue)
      if (res.transactionHash) {
        setTxHash(res.transactionHash)
      } else {
        if (res.data?.code === -32000) {
          toast('Insufficient FTM balance', toastOptions)
        } else {
          handleError(res)
        }
      }
    } else {
      const res = await lockTokensToFantom(inputValue)
      if (res.transactionHash) {
        setTxHash(res.transactionHash)
      } else {
        if (res.data?.code === -32000) {
          toast('Insufficient KAVA balance', toastOptions)
        } else {
          handleError(res)
        }
      }
    }
    document.getElementById('bridge_amount').value = null;
    setInputValue(0)
    getBalance()
  }

  const handleError = (error) => {
    const err = (error?.toString().split('code=')[1])
    if (err?.toString().split(',')[0] === 'ACTION_REJECTED') {
      toast('User denied transaction.', toastOptions)
    } else {
      toast('Something went wrong.', toastOptions)
    }
  }

  const toastOptions = {
    type: "warning",
    autoClose: 3000,
    position: "top-center",
    closeButton: false,
    hideProgressBar: true,
    closeOnClick: false,
    icon: false,
    transition: Slide,
    theme: 'dark'
  }

  const handleKavaChainChange = async () => {
    const chainID = configs.KavaMainnetChainId;
    if (chainId !== null && chainId !== chainID) {
      setLoading(true)
      toast("Switch to Kava EVM", { ...toastOptions, toastId: chainId, autoClose: false });
      try {
        await window.ethereum
          .request({
            method: "wallet_switchEthereumChain",
            params: [{ chainId: chainID }],
          })
          .then(() => {
            window.location.reload(false)
          })
      } catch (e) {
        if (e.code === 4902) {
          await window.ethereum.request({
            method: 'wallet_addEthereumChain',
            params: [
              {
                chainId: '0x8ae',
                chainName: 'Kava',
                rpcUrls: ['https://evm.kava.io'],
                nativeCurrency: {
                  name: "KAVA",
                  symbol: "KAVA",
                  decimals: 18
                },
                blockExplorerUrls: ["https://explorer.kava.io"]
              },
            ],
          }).then(() => {
            window.location.reload(false);
          })
        }
      }
    }
  }

  const handleFantomChainChange = async () => {
    const chainID = configs.FantomMainnetChainId;
    if (chainId !== null && chainId !== chainID) {
      setLoading(true)
      toast("Switch to Fantom Network", { ...toastOptions, toastId: chainId, autoClose: false });
      try {
        await window.ethereum
          .request({
            method: "wallet_switchEthereumChain",
            params: [{ chainId: chainID }],
          })
          .then(() => {
            window.location.reload(false)
          })
      } catch (e) {
        if (e.code === 4902) {
          await window.ethereum.request({
            method: 'wallet_addEthereumChain',
            params: [
              {
                chainId: '0xfa',
                chainName: 'Fantom',
                rpcUrls: ['https://ftm.getblock.io/01ad4c6d-8ae5-420a-ae27-b3a43c5401a4/mainnet/'],
                nativeCurrency: {
                  name: "FTM",
                  symbol: "FTM",
                  decimals: 18
                },
                blockExplorerUrls: ["https://ftmscan.com/"]
              },
            ],
          }).then(() => {
            window.location.reload();
          })
        }
      }
    }
  }

  return (
    <>
      <ToastContainer/>
      {status === 'notConnected' || status === 'unavailable' ?
        <Connect status={status} connect={connect}/> :
        <div className='home_div'>
          <BridgeBox account={account}
                     setIsSwitchOn={setIsSwitchOn}
                     isSwitchOn={isSwitchOn}
                     walletBalance={walletBalance}
                     accountBalance={accountBalance}
                     handleKavaChainChange={handleKavaChainChange}
                     handleGetValue={handleGetValue}
                     inputValue={inputValue}
                     handleMax={handleMax}
                     txHash={txHash}
                     chainId={chainId}
                     loading={loading}
                     status={status}
                     approvedBalance={approvedBalance}
                     handleBridge={handleBridge}
                     handleApprove={handleApprove}
                     handleFantomChainChange={handleFantomChainChange}
          />
        </div>
      }
    </>
  );
}

export default App;
