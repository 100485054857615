import {ethers} from "ethers";
import configs from "../utils/config.json";
import fantomTokenAbi from './fantomTokenAbi.json';
import fantomLockerAbi from './fantomLockerAbi.json';
import kavaTokenAbi from './kavaTokenAbi.json';

let fantomTokenContract;
let fantomLockerContract;
let kavaTokenContract;

export const providerHandler = async () => {
  const provider = new ethers.providers.Web3Provider(window.ethereum);
  const account = await provider.listAccounts();
  const address = account[0];
  const signer = provider.getSigner();
  fantomTokenContract = new ethers.Contract(configs.fantomTokenAddress, fantomTokenAbi, signer);
  fantomLockerContract = new ethers.Contract(configs.fantomLokerAddress, fantomLockerAbi, signer);
  kavaTokenContract = new ethers.Contract(configs.kavaTokenAddress, kavaTokenAbi, signer);
  return address;
};

export const getWalletBalance = async () => {
  const provider = new ethers.providers.Web3Provider(window.ethereum);
  const signer = provider.getSigner();
  const balance = await signer.getBalance();
  return ethers.utils.formatEther(balance);
}

export const fantomBalance = async (userAddress) => {
  const n = await fantomTokenContract.balanceOf(userAddress)
  return ethers.utils.formatEther(n);
}

export const kavaBalance = async (userAddress) => {
  const n = await kavaTokenContract.balanceOf(userAddress)
  return ethers.utils.formatEther(n);
}

export const approvedAmount = async (userAddress) => {
  const n = await fantomTokenContract.allowance(userAddress, configs.fantomLokerAddress)
  return ethers.utils.formatEther(n);
}

export const fantomApproval = async (amount) => {
  try {
    const n = await fantomTokenContract.approve(configs.fantomLokerAddress, ethers.utils.parseEther(amount.toString()))
    return await n.wait();
  } catch (error) {
    console.log("Error while approving :-", error);
    return false
  }
}

export const lockTokensToKava = async (amount) => {
  try {
    const n = await fantomLockerContract.lockAndTransferTokenToKava(ethers.utils.parseEther(amount.toString()), { value: ethers.utils.parseEther('1') })
    return n.wait();
  } catch (error) {
    console.log("Error while transferToKava :-", error);
    return error
  }
}

export const lockTokensToFantom = async (amount) => {
  try {
    const n = await kavaTokenContract.transferRemote(ethers.utils.parseEther(amount.toString()), { value: ethers.utils.parseEther('0.1') })
    return n.wait();
  } catch (error) {
    console.log("Error while transferToFantom :-", error);
    return error
  }
}